import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"

import { useGTMInit} from '@/hooks/useGTM.js'


const Index = () => {
  React.useEffect(()=>{
    useGTMInit('support','XREAL_Beam_Pro_User_Manuals')
  },[])
  return (
  <>
    <DownloadFilePage
      downloadURL={`${process.env.resourceUrl}docs/manual/XREAL_Beam_Pro_User_Manuals.pdf`}
    />
  </>
)
}

export default Index